<script setup lang="ts">
import { RouterView } from 'vue-router'
import { NotificationGroup, Notification } from 'notiwind'
import Checkmark from './symbols/Checkmark.vue'
import CloudRainFill from './symbols/CloudRainFill.vue'
import Info from './symbols/Info.vue'
import NotificationView from './components/NotificationView.vue'
import { useAuth } from 'vue-clerk'
import { watch } from 'vue'
import * as Sentry from '@sentry/vue'
import { usePostHog } from '@/plugins/posthog/usePosthog'

const { userId } = useAuth()
const postHog = usePostHog()

watch(userId, (newUserId) => {
    if (newUserId) {
        postHog.identify(newUserId)
        Sentry.setUser({ id: newUserId })
        localStorage.setItem('userId', newUserId)
    } else {
        localStorage.removeItem('userId')
    }
})

</script>

<template>
    <RouterView />

    <NotificationGroup group="error">
        <div aria-live="assertive"
            class="z-50 pointer-events-none fixed inset-0 flex flex-col items-end px-6 py-14 sm:items-start">
            <Notification v-slot="{ notifications, close }" enter="transform ease-out duration-200 transition"
                enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-200"
                leave-from="opacity-100" leave-to="opacity-0" move="transition duration-200" move-delay="delay-200"
                :max-notifications="1">
                <NotificationView
                    v-for="notification in notifications" :key="notification.id"
                    :title="notification.title as string"
                    :message="notification.text as string"
                    :icon="CloudRainFill"
                    @close="close(notification.id)"
                />
            </Notification>
        </div>
    </NotificationGroup>

    <NotificationGroup group="success">
        <div aria-live="assertive"
            class="z-50 pointer-events-none fixed inset-0 flex flex-col items-end px-6 py-14 sm:items-start">
            <Notification v-slot="{ notifications, close }" enter="transform ease-out duration-200 transition"
                enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-200"
                leave-from="opacity-100" leave-to="opacity-0" move="transition duration-200" move-delay="delay-200"
                :max-notifications="1">
                <NotificationView
                    v-for="notification in notifications" :key="notification.id"
                    :title="notification.title as string"
                    :message="notification.text as string"
                    :icon="Checkmark"
                    @close="close(notification.id)"
                />
            </Notification>
        </div>
    </NotificationGroup>

    <NotificationGroup group="info">
        <div aria-live="assertive"
            class="z-50 pointer-events-none fixed inset-0 flex flex-col items-end px-6 py-14 sm:items-start">
            <Notification v-slot="{ notifications, close }" enter="transform ease-out duration-200 transition"
                enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-200"
                leave-from="opacity-100" leave-to="opacity-0" move="transition duration-200" move-delay="delay-200"
                :max-notifications="1">
                <NotificationView
                    v-for="notification in notifications" :key="notification.id"
                    :title="notification.title as string"
                    :message="notification.text as string"
                    :icon="Info"
                    @close="close(notification.id)"
                />
            </Notification>
        </div>
    </NotificationGroup>
</template>
