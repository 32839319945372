import { createRouter, createWebHistory } from 'vue-router'
import { useAuth, useClerkProvider } from 'vue-clerk'
import { until } from '@vueuse/core'

const Dashboard = () => import('@/views/Dashboard/Dashboard.vue')
const Login = () => import('@/views/Login/Login.vue')
const SignUp = () => import('@/views/SignUp/SignUp.vue')
const AuthCallback = () => import('@/views/AuthCallback.vue')
const LinkDetailView = () => import('@/views/Dashboard/LinkDetails/LinkDetailView.vue')
const EmptyDetailPlaceholder = () => import('@/views/Onboarding/EmptyDetailPlaceholder.vue')
const Checkout = () => import('@/views/Checkout.vue')

const Settings = () => import('@/views/Settings/Settings.vue')
const SubscriptionManagement = () => import('@/views/Settings/Subscription/SubscriptionManagement.vue')
const DomainManagement = () => import('@/views/Settings/Domains/DomainManagement.vue')
const APIKeyManagement = () => import('@/views/Settings/APIKeys/APIKeyManagement.vue')

const AccountPortal = () => import('@/views/AccountPortal.vue')

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                title: 'Short Menu – Dashboard',
                authIfNeeded: true
            },
            children: [
                {
                    path: '/',
                    name: 'empty',
                    component: EmptyDetailPlaceholder
                },
                {
                    path: '/links/:id',
                    name: 'link',
                    component: LinkDetailView
                }
            ]
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                title: 'Short Menu – Settings',
                authIfNeeded: true
            },
            children: [
                {
                    path: 'subscription',
                    name: 'subscription',
                    component: SubscriptionManagement,
                    meta: {
                        title: 'Short Menu – Subscription'
                    }
                },
                {
                    path: 'domains',
                    name: 'domains',
                    component: DomainManagement,
                    meta: {
                        title: 'Short Menu – Domains'
                    }
                },
                {
                    path: 'api-keys',
                    name: 'api-keys',
                    component: APIKeyManagement,
                    meta: {
                        title: 'Short Menu – API Keys'
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: 'Short Menu – Login',
                skipIfAuth: true
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUp,
            meta: {
                title: 'Short Menu – Sign Up',
                skipIfAuth: true
            }
        },
        {
            path: '/oauth-callback',
            component: AuthCallback,
            meta: {
                title: 'Short Menu - Loading…'
            }
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: Checkout,
            meta: {
                title: 'Short Menu – Checkout',
                authIfNeeded: true
            }
        },
        {
            path: '/account-portal',
            name: 'account-portal',
            component: AccountPortal,
            meta: {
                title: 'My Account | Short Menu',
            }
        }
    ]
})

router.beforeEach(async (to, from) => {
    const { isClerkLoaded } = useClerkProvider()
    if (!isClerkLoaded.value) {
        await until(isClerkLoaded).toBe(true)
    }
})

router.afterEach(route => {
    document.title = route.meta.title as string || 'Short Menu'
})

router.beforeResolve((to, from, next) => {
    const { isSignedIn } = useAuth()
    
    if (to.matched.some(record => record.meta.authIfNeeded) && isSignedIn.value !== true) {
        next({
            name: 'login',
            query: to.fullPath === '/' ? undefined : { destination: to.fullPath }
        })

        return
    }

    if (to.matched.some(record => record.meta.skipIfAuth) && isSignedIn.value === true) {
        const destination = to.query.destination as string | undefined
        if (destination) {
            next(destination)
            return
        }

        next({
            name: 'dashboard'
        })

        return
    }

    if (to.name === 'settings') {
        next({
            name: 'subscription'
        })
        
        return
    }

    next()
})

export default router
