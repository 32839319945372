import { createInput, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import ToggleInput from './components/ToggleInput.vue'
import PictureInput from './components/PictureInput.vue'
import RegionInput from './components/RegionInput.vue'
import TagInput from './components/TagInput.vue'

export default defaultConfig({
    config: {
        classes:  generateClasses({
            text: {
                input: 'bg-background-input rounded-sm px-2 py-1 border border-border-primary drop-shadow-sm w-full placeholder:text-foreground-tertiary ring-background-tertiary outline-none transition-all focus:ring-2 appearance-none focus:outline-none',  
                message: 'text-left text-md text-foreground-warning pt-1',
                label: 'text-left text font-medium text-foreground-secondary pb-1',
                wrapper: 'flex flex-col items-stretch'
            },
            email: {
                input: 'bg-background-input rounded-sm px-2 py-1 border border-border-primary drop-shadow-sm w-full placeholder:text-foreground-tertiary ring-background-tertiary outline-none transition-all focus:ring-2 appearance-none focus:outline-none',  
                message: 'text-left text-md text-foreground-warning pt-1',
                label: 'text-left text font-medium text-foreground-secondary pb-1',
                wrapper: 'flex flex-col items-stretch'
            },
            radio: {
                options: 'flex flex-col items-start gap-y-1',
                wrapper: 'flex flex-row items-center gap-x-1',
                inner: 'flex flex-col items-center',
                message: 'text-left text-md text-foreground-warning pt-1'
            },
            select: {
                input: 'bg-transparent outline-none appearance-none focus:outline-none',  
                message: 'text-left text-md text-foreground-warning pt-1',
                label: 'text-left text font-medium text-foreground-secondary pb-1',
                wrapper: 'flex flex-col items-stretch',
                inner: 'flex flex-row items-center gap-x-1 bg-background-input rounded-sm px-2 py-1 border border-border-primary drop-shadow-sm w-full ring-background-tertiary outline-none transition-all focus:ring-2 appearance-none focus:outline-none',
            },
            toggle: {
                label: 'text-left text font-medium text-foreground-secondary',
                wrapper: 'flex flex-row items-center justify-between'
            },
            picture: {
                label: 'text-left text font-medium text-foreground-secondary',
                wrapper: 'flex flex-col gap-y-1'
            },
            region: {
                message: 'text-left text-md text-foreground-warning pt-1'
            },
            tags: {
                outer: 'z-30'
            }
        }),
    },
    icons: {
        chevronDown: '<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.19824 6.12988C4.98698 6.12988 4.79362 6.04395 4.61816 5.87207L0.57373 1.73633C0.505697 1.66471 0.453776 1.58773 0.417969 1.50537C0.385742 1.42301 0.369629 1.33171 0.369629 1.23145C0.369629 1.09896 0.401855 0.977214 0.466309 0.866211C0.530762 0.755208 0.616699 0.66748 0.724121 0.603027C0.835124 0.538574 0.956868 0.506348 1.08936 0.506348C1.28988 0.506348 1.46533 0.579753 1.61572 0.726562L5.44531 4.6582H4.95654L8.77539 0.726562C8.92578 0.579753 9.10124 0.506348 9.30176 0.506348C9.43424 0.506348 9.5542 0.538574 9.66162 0.603027C9.77262 0.66748 9.86035 0.755208 9.9248 0.866211C9.98926 0.977214 10.0215 1.09896 10.0215 1.23145C10.0215 1.42839 9.95166 1.59489 9.81201 1.73096L5.77832 5.87207C5.69238 5.95801 5.60107 6.02246 5.50439 6.06543C5.4113 6.10482 5.30924 6.1263 5.19824 6.12988Z" fill="currentColor"/></svg>'
    },
    inputs: {
        toggle: createInput(ToggleInput),
        picture: createInput(PictureInput, {
            props: ['imageUrl', 'delete']
        }),
        region: createInput(RegionInput, {
            props: ['delete']
        }),
        tags: createInput(TagInput, {
            props: ['allTags']
        })
    }
})
